if (window.trustedTypes.createPolicy) {
  window.trustedTypes.createPolicy('default', {
    createHTML: function (html) {
      return DOMPurify.sanitize(html, {
        RETURN_TRUSTED_TYPE: true
      })
    },
    createScript: function (script, sink) {
      return DOMPurify.sanitize(script, { RETURN_TRUSTED_TYPE: true })
    },
    createScriptURL: function (scriptURL, sink) {
      return DOMPurify.sanitize(scriptURL, { RETURN_TRUSTED_TYPE: true })
    }
  })
}
